<template>
  <div class="iframe-container">
    
    <div v-if="isLoading" class="loaderbox" style="font-size:30px; color: rgb(66, 68, 69);">
      <div class="loader">{{t(`Loading`)+"..."}}
        <span></span>
      </div>
    </div>

    <div class="icon-container">
    

      <!-- Fullscreen icon -->
      <div @click="toggleFullScreen" class="fullscreen-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="white" d="M6 19h4v2H4v-6h2v4zm12-4h2v6h-6v-2h4v-4zM6 5v4H4V3h6v2H6zm12-2v2h-4v2h6V3h-2z"/>
        </svg>
      </div>

      <!-- Open in new tab icon -->
      <div @click="openGameInNewWindow" class="new-tab-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="white" d="M14 3v2h3.59l-9.7 9.7 1.41 1.41L19 6.41V10h2V3h-7zM5 5h6V3H3v18h18v-8h-2v6H5V5z"/>
        </svg>
      </div>

        <!-- Close icon -->
        <div @click="$router.push('/')"  class="new-tab-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
          <path fill="white" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
          <path d="M0 0h24v24H0z" fill="none"/>
        </svg>
      </div>
    </div>

    <iframe
      class="iframe"
      :src="gameUrl"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import axios from 'axios';
import { decodeData } from "@/utils/utils";

export default {
  name: 'GameIframe',
  data() {
    return {
      gameUrl: '',
      isLoading: true, // Initially set loading state to true
    };
  },

  created() {
    
    if (decodeData(this.$route.params.api_provider_name) === "NEXUSGGREU") {
      this.fetchNexusGameUrl();
    } else if (decodeData(this.$route.params.api_provider_name) === "DREAMGATES") {
      this.fetchDreamGatesGameUrl();
    } else if (decodeData(this.$route.params.api_provider_name) === "EVERGAME") {
      this.fetchEverGameUrl();
    } else if(decodeData(this.$route.params.api_provider_name) === "WORLDSLOT"){
      this.fetchWorldslotGameUrl()
    }else if(decodeData(this.$route.params.api_provider_name) === "DIASLOT"){
      this.fetchDiaslotGameUrl()
    } 
    else if(decodeData(this.$route.params.api_provider_name) === "TIMELESS"){
      this.fetchTimelessGameUrl()
    } 
    else if(decodeData(this.$route.params.api_provider_name) === "GAMES2API"){
      this.fetchGame2apiGameUrl()
    } 
    else {
      this.fetchNexusGameUrl();
    }
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    decodeData,
    openGameInNewWindow() {
      if (this.gameUrl) {
        window.open(this.gameUrl, '_blank');
      }
    },
    toggleFullScreen() {
      const iframeContainer = document.querySelector('.iframe-container');
      if (!document.fullscreenElement) {
        iframeContainer.requestFullscreen().catch(err => {
          toast(`Error attempting to enable fullscreen mode: ${err.message}`);
        });
      } else {
        document.exitFullscreen();
      }
    },
    async fetchGame2apiGameUrl() {
      const token = localStorage.getItem("userToken");
      const username = localStorage.getItem("username");
      try {
        const payload = {
          user_code: username || "demouser01",
          provider_code: decodeData(this.$route.params.provider_id),
          game_code: decodeData(this.$route.params.game_id),
          lang: "en"
        };
        const apiUrl = `${process.env.VUE_APP_API_KEY}/api/secondary-game/games2api/get-game-url?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
        const response = await axios.post(apiUrl, payload);
        if (response.data.status === 200) {
          this.gameUrl = response.data.data.launch_url;
        } else {
          toast(response.data.message);
        }
      } catch (error) {
        toast(error.response?.data?.message || error?.message);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchNexusGameUrl() {
      const token = localStorage.getItem("userToken");
      const username = localStorage.getItem("username");
      try {
        const payload = {
          user_code: username || "demouser01",
          provider_code: decodeData(this.$route.params.provider_id),
          game_code: decodeData(this.$route.params.game_id),
          lang: "en"
        };
        const apiUrl = `${process.env.VUE_APP_API_KEY}/api/secondary-game/nexus/get-game-url?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
        const response = await axios.post(apiUrl, payload);
        if (response.data.status === 200) {
          this.gameUrl = response.data.data.launch_url;
        } else {
          toast(response?.data?.message);
        }
      } catch (error) {
        toast(error?.response?.data?.message || error?.message);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDreamGatesGameUrl() {
      const token = localStorage.getItem("userToken");
      const username = localStorage.getItem("username");
      const user_code = localStorage.getItem("user_code");
      try {
        const payload = {
          user_code: user_code || 400202552,
          provider_id: decodeData(this.$route.params.provider_id),
          game_symbol: decodeData(this.$route.params.game_id),
          lang: 1,
          win_ratio: 0
        };
        const apiUrl = `${process.env.VUE_APP_API_KEY}/api/secondary-game/dreamgates/get-game-url?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
        const response = await axios.post(apiUrl, payload);
        if (response.data.status === 200) {
          this.gameUrl = response.data.data.game_url;
        } else {
          toast(response?.data?.message);
        }
      } catch (error) {
        toast(error?.response?.data?.message || error?.message);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchEverGameUrl() {
      const token = localStorage.getItem("userToken");
      const username = localStorage.getItem("username");
      try {
        const payload = {
          userCode: username || "Thomas1",
          nickName: decodeData(this.$route.params.game_id),
          vendorCode: decodeData(this.$route.params.provider_id),
          language: "en"
        };
        const apiUrl = `${process.env.VUE_APP_API_KEY}/api/secondary-game/evergame/get-game-url?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
        const response = await axios.post(apiUrl, payload);
        console.log(response,response.status , response.data.data?.launchUrl,"cehcking response123")
        if (response.status === 200) {
          this.gameUrl = response.data?.data.launchUrl;
        } else {
          toast(response?.data?.message);
        }
      } catch (error) {
        console.log(error,"cechk error")
        toast(error?.response?.data?.message || error?.message);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchWorldslotGameUrl() {
      const token = localStorage.getItem("userToken");
      const username = localStorage.getItem("username");
      try {
        const payload = {
        user_code: username, 
        game_type: "slot",
        provider_code: decodeData(this.$route.params.provider_id),
        game_code: decodeData(this.$route.params.game_id),
        lang: "en",
        };
        const apiUrl = `${process.env.VUE_APP_API_KEY}/api/secondary-game/worldslot/get-game-url?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
        const response = await axios.post(apiUrl, payload);
        if (response.status === 200) {
          this.gameUrl = response.data?.data.launch_url;
        } else {
          toast(response?.data?.message);
        }
      } catch (error) {
        console.log(error)
        toast(error?.response?.data?.message || error?.message);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchTimelessGameUrl() {
      const token = localStorage.getItem("userToken");
      const username = localStorage.getItem("username");
      try {
        const payload = {
        user_code: username, 
        provider_code: decodeData(this.$route.params.provider_id),
        game_code: decodeData(this.$route.params.game_id),
        language: "en",
        };
        const apiUrl = `${process.env.VUE_APP_API_KEY}/api/secondary-game/timeless/get-game-url?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
        const response = await axios.post(apiUrl, payload);
        if (response.status === 200) {
          this.gameUrl = response.data?.data.launch_url;
        } else {
          toast(response?.data?.message);
        }
      } catch (error) {
        console.log(error)
        toast(error?.response?.data?.message || error?.message);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDiaslotGameUrl() {
      const token = localStorage.getItem("userToken");
      const username = localStorage.getItem("username");
      try {
        const payload = {
        user_code: username, 
        game_type: "slot",
        provider_code: decodeData(this.$route.params.provider_id),
        game_code: decodeData(this.$route.params.game_id),
        lang: "en",
        };
        const apiUrl = `${process.env.VUE_APP_API_KEY}/api/secondary-game/diaslot/get-game-url?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
        const response = await axios.post(apiUrl, payload);
        if (response.status === 200) {
          this.gameUrl = response.data?.data.launch_url;
        } else {
          toast(response?.data?.message);
        }
      } catch (error) {
        console.log(error)
        toast(error?.response?.data?.message || error?.message);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

    
  <style scoped>
  /* Custom Scrollbar Styles */
  .back-button {
    margin-top: 7%;
    margin-bottom: 20px;
    margin-left: 20px;
    color: "white";
    background-color: white;
    padding: 10px;
    border-radius: 6px;
  }
  
  .iframe-page {
    width: 100%;
    height: 95vh; /* Adjust height as needed */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background: url("../../assets/images/main/bg-dashboard.webp"); */
    /* background-color: blue; */
    position:fixed;
    z-index: 100;
  }
  
  .iframe-container {
    width: 95%;
    margin: auto;
    height: 84vh;
    margin-top: 20px;
    border-radius: 12px;
    flex-grow: 1; /* Allow the iframe container to take up remaining space */
    overflow: hidden; /* Hide any overflow content */ 
  }
  .iframe {
    width: 100%;
    height: 100%;
    /* padding: 10px; */
    background: #000;
    z-index: 1;
  }
  .cross{
    display: flex;
    justify-content:flex-end ;
    cursor: pointer;
    position: absolute;
    padding: 10px;
    right: 40px;
  }
  @media screen and (max-width: 1024px) {
    .cross {
      display: none;
  }
  }
  @media screen and (max-width: 1024px) {
    .iframe-container {
      width: 100%;
      margin: auto;
      margin-top: 0px;
      border-radius: 0px;
      flex-grow: 1; /* Allow the iframe container to take up remaining space */
      overflow: hidden; /* Hide any overflow content */
      height: 100vh;
      margin-bottom: 0px;
    }
    .icon-container {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0px;
  top: 10px;
}

  
    .iframe {
      width: 100%;
      height: 100%;
      padding: 0px;
      /* background: #000; */
    }
  
    
  }
  
  
  .loaderbox {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    background-color: #000000;
  }
  .icon-container {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 40px;
  top: 10px;
}

.fullscreen-icon, .new-tab-icon, .cross {
  cursor: pointer;
  padding: 10px;
}

.iframe-page {
  width: 100%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
}

.iframe-container {
  width: 95%;
  margin: auto;
  height: 84vh;
  margin-top: 20px;
  border-radius: 12px;
  flex-grow: 1;
  overflow: hidden;
}

.iframe {
  width: 100%;
  height: 100%;
  background: #000;
}

.loaderbox {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}
  </style>
  